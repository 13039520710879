   .feature {
    text-align: center;
    margin-top: 80px;
  }
  
  .feature .section-title {
    margin-block-end: 40px;
  }
  
  .feature-card .card-icon {
    width: 150px;
    margin-inline: auto;
  }
  
  .feature-card .card-title {
    margin-block: 35px 15px;
  }
  
  .feature-card .card-text {
    font-size: var(--fs-7);
  }
  /* Ensure h2 is on top and centered */
.section-title {
  position: relative;
  z-index: 2;
}
  
  /* Media query for smaller screens */
  @media screen and (max-width: 668px) {
    .flex-list {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .flex-item {
      margin: 10px 0;
    }
  }
  
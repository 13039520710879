.food__category {
    background: #023047;
    text-align: center;
    margin-top: 30px;
    padding: 20px 0px;
    border-radius: 8px;
  }
  
  .food__category button {
    border: none;
    outline: none;
    padding: 7px 10px;
    background: transparent;
    color: #fff;
  }
  
  .food__category button img {
    width: 20px;
    height: 20px;
  }
  
  .foodBtnActive {
    background: #fff !important;
    border-radius: 5px;
    color: #df2020 !important;
  }
  
  .tasty__treat-title span {
    color: #df2020;
  }
  
  .tasty__treat-desc {
    color: #777;
    line-height: 28px;
  }
  
  .choose__us-title {
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .choose__us-title i {
    font-weight: 500 !important;
    color: #df2020;
  }
  
  .choose__us-desc {
    color: #777;
  }
  
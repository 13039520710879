.review__text {
    color: #777;
    line-height: 30px;
    margin: 40px 0px;
  }
  
  .slider__content img {
    width: 50px;
    height: 50px;
    border-radius: 33%;
    object-fit: cover;
    /* border-radius: 3; */
  }
  
  @media only screen and (max-width: 768px) {
    .review__text {
      font-size: 0.8rem;
      margin: 20px 0px;
    }
  
    .slider__content img {
      width: 35px;
      height: 35px;
    }
  
    .slider__content h6 {
      font-size: 0.8rem;
    }
  }
  
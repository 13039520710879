.carousel {
    padding: 5rem;
    background-color: #fafafa;
    color: #fff;
    height: 75vh;
    width: 100vw;
}

.box {
    /* background-color: lightgreen; */
    height: 270px !important;
    /* width: 200px !important; */
}

.image {
    width: 100%;
    height: 100%;
  }

#doni {
    color: rgb(0, 0, 0);
    font-size: large;
}

.box h3 {
    text-align: center;
    color: red;
    font-size: medium;
}

.slick-slide div {
    margin: 0 1rem;
}

.slick-dots li.slick-active button:before {
color: rgb(76, 76, 76) !important;
}

.slick-dots li button:before {
    color: #000000 !important;
    font-size: 1rem !important;
    top: 2rem !important;
}
 

.slick-next {
    color: black !important;
    background-color: black !important;
    border-radius: 50%;
}

.slick-prev {
    color: black !important;
    background-color: black !important;
    border-radius: 50%;
}